@import '../../scss/variables';

.auth__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &--no-flex {
    display: block;
    margin-top: 10px;
  }
}

.auth-form {
  position: relative;
  min-width: 260px;
  width: 100%;
  background-color: #fff;

  &__greet {
    min-width: 260px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    font-size: 18px;

    &--margin {
      margin: 10px 0;
    }
  }

  &__fieldset {
    min-width: 260px;
    width: 60%;
    margin: 0 auto 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: none;
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 13px 3.125%;
    border: 1px solid #cecece;
    border-radius: 8px;
    font-size: 16px;
    outline: none;

    &:focus {
      border-color: #adafaf;
    }

    &--pass {
      margin: 0 auto 5px;
      font-size: 16px;
    }
  }

  &__pref {
    font-size: 20px;
    margin-right: 5px;
  }

  &__phonebox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__button {
    min-width: 260px;
    width: 100%;
    margin: 10px auto;
    max-width: 100%;
    padding: 12px 0;
    line-height: 1;
    font-size: 16px;
  }

  &__label {
    font-size: 18px;
    margin-top: 5px;

    &--red {
      color: red;
    }

    &--align-left {
      text-align: left;
    }
  }

  &__notification {
    text-align: left;
    font-size: 12px;
    color: #666465;
  }

  &__textarea {
    padding: 10px;
    width: 100%;
    height: 130px;
    border: 1px solid #cecece;
    border-radius: 5px;
    color: #333;
    font-size: 18px;
    line-height: 1.3;
    outline: none;
    resize: none;

    &:focus {
      border-color: #adafaf;
    }
  }

  &__captcha {
    display: block;
    margin: 10px auto;
    width: 150px;
  }

  &__input-box {
    min-width: 260px;
    width: 60%;
    margin: 0 auto 5px;
    position: relative;
  }

  &__error-msg {
    position: absolute;
    width: 100%;
    bottom: -12px;;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    color: $red-color;
    font-size: 11px;
    text-align: center;
  }
}
