$red-color: #ff59a3;
$purple-color: #c882ff;
$grey-color: #e8e8ed;
$dark-grey-color: #8f9399;
$yellow-color: #ffaf0f;
$white-color: #fff;
$black-color: #000;
$green-color: #1ee7ca;
$blue-color: #00b5ef;
$online-color: $green-color;
$button-color: $red-color;
$checkbox-color: $red-color;
$filter-toggle-color: $blue-color;
$popup-bg: rgba(#c882ff, 0.4)
