@font-face {
  font-family: 'ctstencil-bold';
  src:
          url("../fonts/ctstencil-bold.svg") format("svg"),
          //url("../fonts/ctstencil-bold.woff") format("woff"),
          //url("../fonts/ctstencil-bold.woff2") format("woff2"),
          url("../fonts/ctstencil-bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'tele2-sans-regular';
  src:
          url("../fonts/tele2-sans-regular.woff") format("woff"),
          url("../fonts/tele2-sans-regular.eot"),
          url("../fonts/tele2-sans-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'tele2-serif-regular';
  src:
          url("../fonts/tele2-serif-regular.woff") format("woff"),
          url("../fonts/tele2-serif-regular.eot"),
          url("../fonts/tele2-serif-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
