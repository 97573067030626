@import '../../scss/variables';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  padding: 5px 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $popup-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 21px;
    overflow: visible;
    height: 21px;
    margin: 0;
    padding: 0;
    border: none;
    display: inline-block;
    text-align: left;
    outline: none;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    z-index: 1;
  }

  &__close-icon {
    width: 21px;
    height: 21px;
    z-index: 1;
  }

  &__dialog {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: auto;
    width: 88.88889%;
    max-width: 670px;
    max-height: 90%;
    background-color: $white-color;
    border-radius: 10px;
  }

  &__extra-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--flex-row {
      padding: 0 20px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__title {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 20px 30px;
    background-color: $purple-color;
    color: $white-color;
    border-bottom: none;
    font-size: 21px;
    text-align: center;
    font-weight: 700;
  }

  &__message {
    padding: 0 20px;
    color: #333;
    margin: 1em 0;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
  }

  &__img {
    margin: 0 auto;
    width: 50%;
  }

  &__button {
    margin: 10px 0;
    width: 100%;
    max-width: 180px;
    padding: 12px 0;
    line-height: 1;
    font-size: 16px;

    &--small {
      width: 42%;
    }
  }

  &__exit {
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    display: block;
    width: 100%;
    max-width: 180px;
    font-size: 15px;
    line-height: 1;
    border-radius: 5px;
    margin: 0 auto;
    padding: 0;
    padding-top: 10px;
    color: #0083ca;
    border: none;
    background-color: transparent;
  }
}
