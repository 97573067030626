.button {
  outline: none;
  border: none;
  border-radius: 40px;
  color: $white-color;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent;

  &--red {
    background-color: $blue-color;
    border: 1px solid $blue-color
  }

  &--pink {
    background-color: $red-color;
    border: 1px solid $red-color;
  }

  &--transparent {
    background-color: transparent;
    border: 1px solid $black-color;
    color: $black-color;
  }

  &--grey {
    background-color: $grey-color;
    border: 1px solid $grey-color;
  }

  &--dark-grey {
    background-color: $dark-grey-color;
    border: 1px solid $dark-grey-color;
  }

  &--vk {
    background-color: #4c75a3;
    border: 1px solid #4c75a3;
  }

  &--ok {
    background-color: #f99400;
    border: 1px solid #f99400;
  }

  &:focus {
    outline: none;
  }
}

.input {
  outline: none;
  border: none;

  &:focus {
    outline: none;
  }
}
