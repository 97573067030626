@import '../../scss/variables';

.nav-container {
  position: absolute;
  right: 0;
  top: 0;
}

.nav-button__container {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: $white-color;
  transition: 0.5s ease all;

  &--active {
    background-color: $blue-color;
    transition: 0.5s ease all;
  }
}

.nav-button {
  position: relative;
  width: 50px;
  overflow: visible;
  height: 50px;
  padding: 15px;
  margin: 0;
  border: none;
  display: inline-block;
  text-align: left;
  text-indent: -9999px;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s ease all;

  &--active {
    transition: 0.5s ease all;
    .nav-button__line {
      background-color: $white-color;
      transition: 0.5s ease all;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -14px;
      border: 7px solid transparent;
      border-top: 7px solid $blue-color;
      z-index: 9999;
      transition: 0.5s ease all;
    }
  }

  &__line {
    display: block;
    margin-bottom: 4px;
    height: 2px;
    background-color: $black-color;
  }

  &__line:last-child {
    margin-bottom: 0;
  }

  &--unread::after {
    content: '';
    width: 7px;
    height: 7px;
    background-color: $red-color;
    display: block;
    top: 15px;
    right: 12px;
    position: absolute;
    border-radius: 50%;
  }
}

.user-nav {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 999;
  height: initial;
  min-height: initial;
  min-width: 50px;
  max-width: 50px;
  background-color: white;
  margin: 0 auto;
  width: 100%;
  border: 1px solid $grey-color;
  transition: 0.5s ease all;

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    width: 50px;
    height: 50px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.5s ease all;

    &--active {
      transition: 0.5s ease all;
      path {
        fill: $red-color;
      }
    }
  }

  &__new-messages {
    position: absolute;
    top: 18%;
    left: 45%;
    transform: translate(-50%);
    font-size: 10px;
    color: $black-color;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.disable--scroll {
  overflow: hidden;
}
