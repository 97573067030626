@import '../../scss/variables';

.sub {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  max-width: 720px;
  background-color: $grey-color;
  background-image: url('../../images/sub-bg.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white-color;

  &__title {
    margin-bottom: auto;
    font-size: 24px;
  }

  &__button {
    font-size: 26px;
    padding: 18px;
    width: 90%;
  }

  &__footer {
    text-align: center;
    margin-top: auto;
    font-size: 10px;
    margin-bottom: 10px;
    line-height: 1.3;
  }

  &__img {
    max-width: 90%;
    margin: 11vh 0 -20vh;;
  }

  &__link {
    color: $white-color;
  }

  &:after {
    content: '';
    position: absolute;
    right: 14px;
    top: 10px;
    width: 30px;
    height: 30px;
    background: url('../../images/18+.png');
    background-size: cover;
  }
}
