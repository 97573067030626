@import '../../scss/variables';

.header {
  position: relative;
  background-color: white;
  //background-image: url('../../images/header-bg.png');
  background-position: top center;
  background-size: contain;
  min-height: 51px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;

  &--flex-start {
    justify-content: flex-start;
    padding-left: 15px;
  }

  &--border-bottom {
    border-bottom: 1px solid $grey-color;
  }

  &__title {
    font-family: 'ctstencil-bold', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
  }

  &__username {
    font-family: 'tele2-serif-regular', Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    position: absolute;
    left: 80px;
    top: 50%;
    transform: translateY(-50%);

    &--center {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
    }
  }

  &__back-link {
    margin-right: 10px;
  }

  &__logo {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    width: 58px;
    height: auto;

    img {
      margin-top: 5px;
      width: 100%;
    }

    &--avatar {
      border-radius: 50%;
      width: 40px;

      img {
        margin-top: 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        display: block;
        width: 100%;
      }
    }

    &--placeholder {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: $grey-color;
      left: -1px;
      top: -1px;
    }
  }

  &__stretcher {
    width: 0;
    height: 0;
    padding-top: 100%;
  }

  &__age {
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 9px;
    font-style: normal;
    display: block;
    text-align: center;
    line-height: 20px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #000;
  }
}
