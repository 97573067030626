html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #686868;
  color: #333;
  font-family: 'tele2-sans-regular', Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  overflow-x: hidden;
}

#root {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.necessary-width {
  width: calc(100vw - (100vw - (100vh * (3 / 4))));
  margin: 0 auto;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
}

.ext-wrapper {
  margin: 0 auto;
  height: 0;
  width: 100%;
  padding-top: 133.3%;
  position: relative;

  @media screen and (max-width: 720px) {
    height: 100%;
    position: inherit;
    padding-top: 0;
  }
}

.wrapper {
  //position: absolute;
  //left: 0;
  //right: 0;
  //top: 0;
  //bottom: 0;
  //background-color: white;
  //  //margin: 0 auto;
  //  //max-width: 720px;
  //  //width: 100%;
  //  //min-height: 100%;
  //  ////height: 100%;
  //  //display: flex;
  //  //flex-direction: column;
  //  //text-align: center;
  position: relative;
  background-color: white;
  margin: 0 auto;
  max-width: 720px;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

.visually-hidden {
  position: fixed;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
